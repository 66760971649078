import React, {useState} from 'react';
import './banner.css';
import {useNavigate} from 'react-router-dom';

function Banner() {
    const navigate = useNavigate()
    const [selectedButton, setSelectedButton] = useState(null);

    const handleClick = (btn) => {
        setSelectedButton(btn);

        // Navigate based on the button clicked
        switch (btn) {
            case 'btn0':
                navigate('/')
                break
            case 'btn1':
                navigate('/host')
                break;
            case 'btn2':
                navigate('/dj')
                break;
            case 'btn3':
                navigate('/bar')
                break;
            default:
                break;
        }
    }

    return (
        <div className="banner">
            <div className="logo-link" onClick={() => handleClick('btn0')}>
                <img src="/logo_blank.png" alt="Mockingbird Logo" className="company-logo" />
            </div>
            <div className="button-container">
                <button
                    className={`banner-button ${selectedButton === 'btn1' ? 'active' : ''}`}
                    onClick={() => handleClick('btn1')}>
                    For Hosts
                </button>
                <button
                    className={`banner-button ${selectedButton === 'btn2' ? 'active' : ''}`}
                    onClick={() => handleClick('btn2')}>
                    For DJs
                </button>
                <button
                    className={`banner-button ${selectedButton === 'btn3' ? 'active' : ''}`}
                    onClick={() => handleClick('btn3')}>
                    For Bars
                </button>
                <button className='banner-button' onClick={() => window.open('https://raydelv.tech/contact')}>Contact</button>
            </div>
        </div>
    );
}

export default Banner;
