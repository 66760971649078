import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './GLOBAL.css'
import Home from './components/home';
import Hosts from "./components/hosts";
import DJs from "./components/djs";
import Bars from "./components/bars";
import Banner from "./components/banner";
import Footer from "./components/footer";
import NotFound from "./components/notfound";

function App() {
    return (
        <Router>
            <div>
                <Banner />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/host" element={<Hosts />} />
                    <Route path="/dj" element={<DJs />} />
                    <Route path="/bar" element={<Bars />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
