import React from 'react';
import './hosts.css'

function Hosts() {
    return (
        <body>
        <div className="App">
            <div className="content-wrapper">
                <p className="text">Coming soon for hosts...</p>
            </div>
        </div>
        </body>
    );
}

export default Hosts;