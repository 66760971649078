import React from 'react';
import './bars.css'

function Bars() {
    return (
        <body>
        <div className="App">
            <div className="content-wrapper">
                <p className="text">Coming soon for bars...</p>
            </div>
        </div>
        </body>
    );
}

export default Bars;