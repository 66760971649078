import React from 'react';
import './notfound.css'

function NotFound() {
    return (
        <body>
        <div className="App">
            <div className="content-wrapper">
                <div> Whoops! Page not found. </div>
            </div>
        </div>
        </body>
    );
}

export default NotFound;