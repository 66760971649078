import React from 'react';
import './home.css'

function Home() {
    return (
        <body>
        <div className="App">
            <div className="content-wrapper">
                <div> mockingbird.live </div>
            </div>
        </div>
        </body>
    );
}

export default Home;