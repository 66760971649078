import React from 'react';
import './footer.css';
import { FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

function Footer() {
    return (
        <div className="footer">
            <div className="footer-social">
                <a href="https://www.instagram.com/raynoceros_/" target="_blank" rel="noopener noreferrer">
                    <FaInstagram size={24} color="white" />
                </a>
                <a href="https://twitter.com/raydelvecc" target="_blank" rel="noopener noreferrer">
                    <FaTwitter size={24} color="white" />
                </a>
                <a href="https://www.linkedin.com/in/raymond-del-vecchio/" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin size={24} color="white" />
                </a>
            </div>
            <div className="footer-links">
                <span>© 2023 Mockingbird</span>
            </div>
        </div>
    );
}

export default Footer;
